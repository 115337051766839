export const environment = {
  name: 'Development',
  production: false,
  reCaptchaSiteKey: '6LdDnocgAAAAAOruOo4KNYI7GwwgwfmmMpJNH3Cg',
  ccVoteApiBaseUrl: 'https://devapi.ciravote.com/api',
  sentryDsnUrl: 'https://f03478122781926142dbbb0a82233e10@sentry.ciranet.com/6',
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0
};
